import React from 'react';
import ReactMarkdown from "react-markdown"
import { graphql, Link } from "gatsby"

import Layout from "../mainComponents/Layout"
import Footer from "../mainComponents/Footer"
import FooterMail from "../components/FooterMail"
import SEO from '../mainComponents/seo';

import makeUrlValid from "../components/makeUrlValid"

import "./article.scss"
import Wrapper from '../mainComponents/Wrapper';

export const query = graphql`
query ArticleQuery($id: Int!) {
	strapiArticles(strapiId: { eq: $id }) {
        strapiId
        Preview {
            publicURL
        }
        Preview_image {
            publicURL
        }
        Title
        Subtitle
        background_image {
            publicURL
        }
        Text
        author {
            Photo {
            publicURL
            }
            Name
            Position
        }
        categories {
            id
            Tag
        }
    }
    allStrapiArticles(limit: 4) {
        edges {
          node {
            strapiId
            Preview {
              publicURL
            }
            Title
            categories {
              id
              Tag
            }
            author {
              Photo {
                publicURL
              }
              Name
              Position
            }
          }
        }
    }
}
`;

const blogItem = ({ data }) => {

    const article = data.strapiArticles;
    const articles = data.allStrapiArticles.edges;
    const author = data.strapiArticles.author;
    const categories = data.strapiArticles.categories;

    return (
        <Wrapper>
        <Layout>
            <SEO title={`${article.Title}`} img={article.Preview_image.publicURL} description={article.Subtitle}/>

            <section className="article-head" style={{backgroundImage: `url(${article.background_image?.publicURL ?? article.Preview.publicURL})`}}>
                <h4 className="article-head__title">{article.Title}</h4>
                <h5 className="article-head__subtitle">{article.Subtitle}</h5>
            </section>

            <section className="article-info">
                <div className="article-info__wrapper">
                    <div className="article-info__block">
                        <img className="article-info__photo" src={author.Photo.publicURL} alt={author.Name}/>

                        <h6 className="article-info__name">{author.Name}</h6>
                        <p className="article-info__position">{author.Position}</p>
                    </div>

                    <div className="article-info__block">
                        {categories.map(elem =>
                            <p className={`article-info__tag article-info__tag_${elem.Tag.toLowerCase()}`} key={elem.id}>{elem.Tag.toLowerCase()}</p>
                        )}
                    </div>

                    <div className="article-info__block">
                        <p className="article-info__share">Share on</p>

                        <a href={`https://twitter.com/intent/tweet?text=http://movadex.com/blog/article/${makeUrlValid(article.Title)}`} className="article-info__link" target="_blank" rel="noopener noreferrer">
                            <svg className="article-info__svg" width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.633 3.99704C17.646 4.17204 17.646 4.34604 17.646 4.52004C17.646 9.84504 13.593 15.981 6.186 15.981C3.904 15.981 1.784 15.32 0 14.172C0.324 14.209 0.636 14.222 0.973 14.222C2.856 14.222 4.589 13.586 5.974 12.501C4.203 12.464 2.719 11.304 2.207 9.70804C2.456 9.74504 2.706 9.77004 2.968 9.77004C3.329 9.77004 3.692 9.72004 4.029 9.63304C2.182 9.25904 0.799 7.63804 0.799 5.68004V5.63004C1.336 5.92904 1.959 6.11604 2.619 6.14104C1.534 5.41904 0.823 4.18404 0.823 2.78704C0.823 2.03904 1.022 1.35304 1.371 0.755043C3.354 3.19804 6.335 4.79504 9.677 4.97004C9.615 4.67004 9.577 4.35904 9.577 4.04704C9.577 1.82704 11.373 0.019043 13.605 0.019043C14.765 0.019043 15.812 0.505043 16.548 1.29104C17.458 1.11604 18.33 0.779043 19.104 0.318043C18.805 1.25304 18.168 2.03904 17.333 2.53804C18.144 2.45004 18.93 2.22604 19.652 1.91404C19.104 2.71204 18.419 3.42304 17.633 3.99704Z" fill="#7E8086"/>
                            </svg>
                        </a>

                        <a href={`https://www.linkedin.com/shareArticle?mini=true&url=http://movadex.com/blog/article/${makeUrlValid(article.Title)}`} className="article-info__link" target="_blank" rel="noopener noreferrer">
                            <svg className="article-info__svg" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17 0H1C0.447 0 0 0.448 0 1V17C0 17.552 0.447 18 1 18H17C17.553 18 18 17.552 18 17V1C18 0.448 17.553 0 17 0ZM5.339 15.337H2.667V6.747H5.339V15.337ZM4.003 5.574C3.147 5.574 2.455 4.88 2.455 4.026C2.455 3.172 3.146 2.478 4.003 2.478C4.857 2.478 5.551 3.171 5.551 4.026C5.551 4.881 4.857 5.574 4.003 5.574ZM15.338 15.337H12.669V11.16C12.669 10.164 12.651 8.883 11.281 8.883C9.891 8.883 9.68 9.969 9.68 11.09V15.338H7.013V6.748H9.573V7.922H9.61C9.965 7.247 10.837 6.535 12.134 6.535C14.838 6.535 15.337 8.313 15.337 10.627V15.337H15.338Z" fill="#7E8086"/>
                            </svg>
                        </a>

                        <a href={`https://www.facebook.com/sharer/sharer.php?u=http://movadex.com/blog/article/${makeUrlValid(article.Title)}`} className="article-info__link" target="_blank" rel="noopener noreferrer">
                            <svg className="article-info__svg" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17 0H1C0.447 0 0 0.448 0 1V17C0 17.552 0.447 18 1 18H9.615V11.04H7.277V8.315H9.615V6.315C9.615 3.99 11.035 2.723 13.115 2.723C13.814 2.721 14.514 2.757 15.21 2.83V5.25H13.775C12.647 5.25 12.427 5.788 12.427 6.575V8.31H15.124L14.774 11.035H12.426V18H17C17.553 18 18 17.552 18 17V1C18 0.448 17.553 0 17 0Z" fill="#7E8086"/>
                            </svg>
                        </a>
                    </div>
                </div>
            </section>

            <section className="article-item">
                <div className="article-item__letter">{article.Text[0].toUpperCase()}</div>

                <ReactMarkdown source={article.Text}/>
            </section>

            <section className="article-more">
                <div className="container">
                    <h3 className="article-more__title">More articles</h3>

                    <div className="blog__block">
                        {articles.map(({ node }) => node.strapiId !== article.strapiId ?
                        <Link to={`/blog/article/${makeUrlValid(node.Title)}`} className="blog-item" key={node.strapiId}>
                            <div className="blog-item__block">
                            <p className="blog-item__view">Read an article</p>
                            <img
                                className="blog-item__image"
                                src={node.Preview.publicURL}
                                alt=""
                            />
                            </div>

                            <div className="blog-item__block">
                            {node.categories.map(elem => (
                                <span
                                key={elem.id}
                                className={`blog-item__tag blog-item__tag_${elem.Tag.toLowerCase()}`}
                                >
                                {elem.Tag.toLowerCase()}
                                </span>
                            ))}
                            </div>

                            <h5 className="blog-item__title">{node.Title}</h5>

                            <div className="blog-author">
                            <div className="blog-author__image">
                                <img
                                className="blog-author__source"
                                src={node.author.Photo.publicURL}
                                alt=""
                                />
                            </div>

                            <div className="blog-author__block">
                                <h6 className="blog-author__title">{node.author.Name}</h6>
                                <p className="blog-author__text">{node.author.Position}</p>
                            </div>
                            </div>
                        </Link>
                        : null)}
                    </div>
                </div>
            </section>
        </Layout>

        <Footer>
            <FooterMail/>
        </Footer>
        </Wrapper>
    );
}

export default blogItem;
